
#mainForm 
{
    margin: 0 auto;
    padding-bottom: 60px;
    width: 30%;
    text-align: center;
    overflow-x: hidden;
}
@media screen and (min-width: 501px) {
#cb_header
{
    color: red;
}
}
@media screen and (max-width: 500px) {
#cb_header
{
    font-size: medium;
    color: red;
}
}

#mainForm input
{
    color:red;
    background-color: #242424;
}
.SubmitButton
{
    padding-top: 10px;
    color: red;
}
.FormInput
{
    margin-bottom: 5px;
}
.divtext
{
    color: red;
}

#game {
    margin: 0 auto;
    padding-bottom: 60px;
    width: 60%;
    max-width: 800px; /* Ограничим максимальную ширину */
    text-align: center;
    color: red;
    overflow-x: hidden;
}

#game input
{
    color:red;
    background-color: #242424;
}


@media screen and (min-width: 501px) {
    #prot_header
    {
        color: yellow;
    }
    }
    @media screen and (max-width: 500px) {
    #prot_header
    {
        font-size: medium;
        color: yellow;
    }
    }
    .divtext_prot
    {
        color: rgb(255, 214, 0);
    }
    .divtext_prot_big
    {
        color: rgb(255, 214, 0);
        font-size: large;
    }



@media screen and (min-width: 501px) {
    #prot_header
    {
        color: yellow;
    }
    }
    @media screen and (max-width: 500px) {
    #prot_header
    {
        font-size: medium;
        color: yellow;
    }
    }
    .divtext_prot
    {
        color: rgb(255, 214, 0);
    }
    .divtext_prot_big
    {
        color: rgb(255, 214, 0);
        font-size: large;
    }

@media screen and (min-width: 501px) {
    #slov_header
    {
        color: rgb(0, 0, 0);
    }
    }
    @media screen and (max-width: 500px) {
    #slov_header
    {
        font-size: medium;
        color: rgb(0, 0, 0);
    }
    }
    .divtext_slov
    {
        color: rgb(0, 0, 0);
        margin: 10px 0;
    }
    .divtext_slov_big
    {
        color: rgb(0, 0, 0);
        font-size: large;
    }

#glassbutton
{
    button{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        background-color: #3ca9fc;
        border: none;
        color: rgb(255, 255, 255);
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        margin: 4px 2px;
        cursor: pointer;
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        width: 100px;
        height: 100px;
    }
}   